/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Outlet } from 'react-router';
import { DraSubmenu } from '../DraBody/DraSubmenu/DraSubMenu';
import { submenuContent } from '../Utils/SubMenuDetails';

export const DraOutlet = () => {
  return (
    <React.Fragment>
      {/* <DraSubmenu content={submenuContent.uspdra} /> */}
      <Outlet />
    </React.Fragment>
  );
};
