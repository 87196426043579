import React from 'react';
import { Banner } from '../Banner';
import { submenuContent } from '../Utils/SubMenuDetails';
import { websiteContent } from '../Utils/website-content';

export const CasBody = () => {
  return (
    <React.Fragment>
      <Banner
        page_title={websiteContent.dashboard_content[1].tileTitle}
        page_description={websiteContent.dashboard_content[1].tileDescription}
      />

      <div data-testid="cas-body" className="frame-wrapper">
        <iframe
          data-testid="cas-iframe"
          title="Customer Analysis and Solutions"
          src="https://uhgazure.sharepoint.com/sites/06_CAS/SitePages/Customer-Analysis-%26-Solutions-(CAS).aspx?ga=1"
          referrerPolicy="no-referrer"
          width="100%"
          height="1000px"
        />
      </div>
    </React.Fragment>
  );
};
