export const websiteContent = {
  page_title: 'Office of Data & Analytics',
  page_description:
    'Best in class analytics community that strives to align the best people in the industry with the most robust health care data in the world.',
  advoAnalytics: {
    aboutus: '',
    contactus: 'mailto:advocacyanalytics@ds.uhc.com',
  },
  cas: {
    aboutus: '',
    contactus: '',
  },
  dataResources: {
    aboutus: '',
    contactus: '',
  },
  naaa: {
    aboutus: '',
    contactus: '',
  },
  uspdra: {
    aboutus: '',
    contactus: '',
  },
  navbar_content: {
    topMenuItems: {
      menuItem1: {
        title: 'Home',
        url: '#',
        menuOptions: [
          {
            label: '',
            target: '_blank',
            url: '',
          },
        ],
      },
      menuItem2: {
        title: 'Advocacy Analytics',
        url: '#',
        menuOptions: [
          {
            label: '',
            target: '_blank',
            url: '',
          },
        ],
      },
      menuItem3: {
        title: 'Customer Analysis & Solutions',
        url: '#',
        menuOptions: [
          {
            label: '',
            target: '_blank',
            url: '',
          },
        ],
      },
      menuItem4: {
        title: 'Data Resources',
        url: '#',
        menuOptions: [
          {
            label: '',
            target: '_blank',
            url: '',
          },
        ],
      },
      menuItem5: {
        title: 'National Accounts Advanced Analytics',
        url: '#',
        menuOptions: [
          {
            label: '',
            target: '_blank',
            url: '',
          },
        ],
      },
      menuItem6: {
        title: 'USP Data, Reporting & Analytics',
        url: '#',
        menuOptions: [
          {
            title: 'Client Reporting ',
            label: 'Client Reporting ',
            // onClick: () => {
            //   window.open('#', '_blank');
            // },
            target: '_blank',
            url: '#',
          },
          {
            title: 'Data Enablement',
            label: 'Data Enablement',
            // onClick: () => {
            //   window.open('#', '_blank');
            // },
            // target: '_blank',
            url: '#',
          },
          {
            title: 'Executive Reporting',
            label: 'Executive Reporting',
            // onClick: () => {
            //   window.open('#', '_blank');
            // },
            // target: '_blank',
            url: '#',
          },
          {
            title: 'USP Operations Reporting & Technology',
            label: 'USP Operations Reporting & Technology',
            onClick: () => {
              window.open('https://uspopsreporting-uat.optum.com/', '_blank');
            },
            // target: '_blank',
            url: 'https://uspopsreporting-uat.optum.com/',
          },
          {
            title: 'USP Operations Reporting & Technology',
            label: 'USP Operations Reporting & Technology',
            onClick: () => {
              window.open('https://uspopsreporting-uat.optum.com/', '_blank');
            },
            // target: '_blank',
            url: 'https://uspopsreporting-uat.optum.com/',
          },
        ],
      },
    },
  },

  dashboard_content: [
    {
      tileTitle: 'Advocacy Analytics',
      tileUrl: '',
      tileImage: './img-02.JPG',
      tileDescription:
        'Leverage Data Analysis, Artificial Intelligence, Process Solutioning, Automation and Technology to help enable Advocacy enhancements, reduce Medical Cost, improve Member Health outcomes, drive Operational/ Process efficiencies and enhance Member Experience.',
      smallDescription:
        'Enable AMTs with comprehensive reporting solutions to tell a data driven and compelling Advocacy story about Member experience and Operations efficiency.',
    },
    {
      tileTitle: 'Customer Analysis & Solutions',
      tileUrl: '',
      tileImage: './img-03.JPG',
      smallDescription:
        'Derive actionable insights from consultative analytics to deliver creative solutions that support the health and well-being of employees and their families, while mitigating escalating health care costs.',
      tileDescription:
        'Our team derives actionable insights from consultative analytics to deliver creative solutions that support the health and well-being of employees and their families, while mitigating escalating health care costs.',
    },
    {
      tileTitle: 'Data Resources',
      tileUrl: '',
      tileImage: './img-04.JPG',
      smallDescription:
        'Helping customers make informed healthcare decisions through data analytics and consultation while maintaining high ethical standards and complying with governing laws to safeguard member, customer and company data.',
      tileDescription:
        'Our team helps customers make informed healthcare decisions through data analytics and consultation while maintaining high ethical standards and complying with governing laws to safeguard member, customer and company data.',
    },
    {
      tileTitle: 'USP Data, Reporting & Analytics',
      tileUrl: '',
      tileImage: './img-05.JPG',
      smallDescription:
        'Ensure that as we develop the platform to enable more customers and product capabilities, that the data and reporting is accurate and comprehensive.',
      tileDescription:
        'Our team ensures that as we grow the UHC Strategic Platform to support UHC E&I, that we are also supporting the data, reporting, and analysis teams to incorporate the USP data into their portfolios. We manage the USP DRA Capital prioritization with Optum Technology and liase to all functional reporting teams within UHC E&I as they work to retrofit their reporting and analysis tools to USP data.',
    },
    {
      tileTitle: 'National Accounts Advanced Analytics ',
      tileUrl: '',
      tileImage: './img-06.JPG',
      smallDescription:
        'Support our most complex clients, studies, and trailblazing analytics.',
      tileDescription:
        'Our team champions and leads innovative and forward-looking analytics, curates differentiating and leading analytics on top and emerging health care priorities, reinforces our value story with analytics that fortify our commitments, highlights pinnacle performance, creates success stories for our HR/benefit leaders, supports growth and retention strategies, and informs, implements and measures customer communication strategies in partnership with Engagement Solutions.',
    },
  ],
  iWantToSection_content: [
    {
      title: 'Request USP Reporting/Workflow  Support',
      link: 'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/USP%20Reporting%20%20Technology%20Intake%20Form/AllItems.aspx?viewpath=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FLists%2FUSP%20Reporting%20%20Technology%20Intake%20Form%2FAllItems%2Easpx',
      onClick: () => {
        window.open(
          'https://uhgazure.sharepoint.com/sites/PolarisProviderOperations1/Reporting_and_Technology/Lists/USP%20Reporting%20%20Technology%20Intake%20Form/AllItems.aspx?viewpath=%2Fsites%2FPolarisProviderOperations1%2FReporting%5Fand%5FTechnology%2FLists%2FUSP%20Reporting%20%20Technology%20Intake%20Form%2FAllItems%2Easpx',
          '_blank'
        );
      },
    },
    {
      title: 'Submit a USP Capital DRA Intake',
      link: '#',
      onClick: () => {
        window.open(
          'https://uhgazure.sharepoint.com/:p:/r/sites/uhc-is/Polaris/PH/_layouts/15/Doc.aspx?sourcedoc=%7B8A9BA5FD-243E-4F52-8915-E69CC4502E2F%7D&file=Intake_CC_PDD%20Process_FINAL.pptx&action=edit&mobileredirect=true',
          '_blank'
        );
      },
    },
    {
      title: 'View the USP Migration Roadmap',
      link: 'https://uhgazure.sharepoint.com/sites/uhc-is/Polaris/PH/SitePages/USP-Roadmap-Tools.aspx',
      onClick: () => {
        window.open(
          'https://uhgazure.sharepoint.com/sites/uhc-is/Polaris/PH/SitePages/USP-Roadmap-Tools.aspx',
          '_blank'
        );
      },
    },
    {
      title: 'View the Membership Migration Scorecard',
      link: 'https://intelligence.uhg.com/mashups/341bed24-c26a-1ac5-c756-d443a3ff533e?folder=3f794e08-056b-3be9-cbab-e367b5bb39a5&report=a9fde690-c049-42cc-b862-e62a3feccb01',
      onClick: () => {
        window.open(
          'https://intelligence.uhg.com/mashups/341bed24-c26a-1ac5-c756-d443a3ff533e?folder=3f794e08-056b-3be9-cbab-e367b5bb39a5&report=a9fde690-c049-42cc-b862-e62a3feccb01',
          '_blank'
        );
      },
    },
  ],
  aboutus_url: '#',
  contactus_url: '#',
};
