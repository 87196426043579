/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { websiteContent } from '../Utils/website-content';

export const MainContent = ({ dashboard_content }) => {
  return (
    <React.Fragment>
      <div className="dashboard-container">
        <div className="card-container">
          {dashboard_content.map((tile, index) => {
            return (
              <div
                className="dashboard-card"
                id={`index-${index}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(tile.tileUrl, '_blank');
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
              >
                <div className="dashboard-card-img">
                  <img
                    src="https://logos-world.net/wp-content/uploads/2021/10/Tableau-Logo.png"
                    alt={tile.tileTitle}
                  />
                </div>
                <div className="dashboard-card-title">{tile.tileTitle}</div>
                <div className="dashboard-card-description">
                  {tile.tileDescription}
                </div>
              </div>
            );
          })}

          {/* <div className="dashboard-card">
            <div className="dashboard-card-img">
              <img
                src="./img-02.JPG"
                alt={ dashboard_content[0].tileTitle}
              />
            </div>
            <div className="dashboard-card-title">
              { dashboard_content[0].tileTitle}
            </div>
            <div className="dashboard-card-description">
              { dashboard_content[0].tileDescription}
            </div>
          </div>

          <div className="dashboard-card">
            <div className="dashboard-card-img">
              <img
                src="./img-03.JPG"
                alt={ dashboard_content[1].tileTitle}
              />
            </div>
            <div className="dashboard-card-title">
              { dashboard_content[1].tileTitle}
            </div>
            <div className="dashboard-card-description">
              { dashboard_content[1].tileDescription}
            </div>
          </div>

          <div className="dashboard-card">
            <div className="dashboard-card-img">
              <img
                src="./img-04.JPG"
                alt={ dashboard_content[2].tileTitle}
              />
            </div>
            <div className="dashboard-card-title">
              { dashboard_content[2].tileTitle}
            </div>
            <div className="dashboard-card-description">
              { dashboard_content[2].tileDescription}
            </div>
          </div>

          <div className="dashboard-card">
            <div className="dashboard-card-img">
              <img
                src="./img-05.JPG"
                alt={ dashboard_content[3].tileTitle}
              />
            </div>
            <div className="dashboard-card-title">
              { dashboard_content[3].tileTitle}
            </div>
            <div className="dashboard-card-description">
              { dashboard_content[3].tileDescription}
            </div>
          </div>

          <div className="dashboard-card">
            <div className="dashboard-card-img">
              <img
                src="./img-06.JPG"
                alt={ dashboard_content[4].tileTitle}
              />
            </div>
            <div className="dashboard-card-title">
              { dashboard_content[4].tileTitle}
            </div>
            <div className="dashboard-card-description">
              { dashboard_content[4].tileDescription}
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
