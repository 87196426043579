import React from 'react';

export const IWantToButton = ({ title, href }) => {
  return (
    <React.Fragment>
      <div className="shortcut-button shortcut-button-text">
        <a className="footer-links" href={href || ''} target="_blank">
          {title}
        </a>
      </div>
    </React.Fragment>
  );
};
