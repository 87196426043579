/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { RouterProvider } from '@abyss/web/ui/RouterProvider';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { Outlet } from 'react-router';

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { ExecutiveReporting } from '@src/DraTeams/ExecutiveReporting/ExecutiveReporting';
import { ClientReporting } from '@src/DraTeams/ClientReporting';
import { DataEnablement } from '@src/DraTeams/DataEnablement';
import { DataExchange } from '@src/DraTeams/DataExchange';
import { Home } from './Home';
import { msalConfig } from './authConfig';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { OfficeBody } from './OfficeBody';
import { AdAnBody } from './AdAnBody';
import { CasBody } from './CasBody';
import { DataResources } from './DataResources';
import { NaaaBody } from './NAAA/NaaaBody';
import { DraBody } from './DraBody';
import { InitiateLogin } from './InitiateLogin';
import { DraOutlet } from './DraOutlet/DraOutlet';
import { websiteContent } from './Utils/website-content';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance?.getActiveAccount()[0]);
}

// Listen for sign-in account and set Active Account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.account) {
    const { account } = event?.payload;
    msalInstance.setActiveAccount(account);
  }
});

const AppBody = () => {
  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <Router.MetaTags title="Office of Data & Analytics" />
        <AuthenticatedTemplate>
          <Navbar />
          <Outlet />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <InitiateLogin />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </React.Fragment>
  );
};

export const Routes = () => {
  return (
    <RouterProvider history="browser">
      <Router.Routes title="{{title}}">
        <Router.Route path="/" element={<AppBody />}>
          <Router.Route
            path="/"
            element={
              <React.Fragment>
                <OfficeBody />
                <Footer />
              </React.Fragment>
            }
          />

          <Router.Route
            path="/advocacy-analytics"
            element={
              <React.Fragment>
                <AdAnBody />
                <Footer contactUs={websiteContent.advoAnalytics.contactus} />
              </React.Fragment>
            }
          />

          <Router.Route
            path="/customer-analysis-and-solutions"
            element={
              <React.Fragment>
                <CasBody />
                <Footer />
              </React.Fragment>
            }
          />

          <Router.Route
            path="/data-resources"
            element={
              <React.Fragment>
                <DataResources />
                <Footer />
              </React.Fragment>
            }
          />

          <Router.Route
            path="/national-accounts"
            element={
              <React.Fragment>
                <NaaaBody />
                <Footer />
              </React.Fragment>
            }
          />

          <Router.Route path="/uspdra" element={<DraOutlet />}>
            <Router.Route
              path="/uspdra/"
              element={
                <React.Fragment>
                  <DraBody />
                  <Footer />
                </React.Fragment>
              }
            />
            <Router.Route
              path="/uspdra/clientReporting"
              element={
                <React.Fragment>
                  <ClientReporting />
                  <Footer />
                </React.Fragment>
              }
            />
            <Router.Route
              path="/uspdra/dataEnablement"
              element={
                <React.Fragment>
                  <DataEnablement />
                  <Footer />
                </React.Fragment>
              }
            />
            <Router.Route
              path="/uspdra/dataExchange"
              element={
                <React.Fragment>
                  <DataExchange />
                  <Footer />
                </React.Fragment>
              }
            />
            <Router.Route
              path="/uspdra/executiveReporting"
              element={
                <React.Fragment>
                  <ExecutiveReporting />
                  <Footer />
                </React.Fragment>
              }
            />
          </Router.Route>
        </Router.Route>
      </Router.Routes>
    </RouterProvider>
  );
};
