/* eslint-disable react/prop-types */
import React from 'react';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

export const SubMenu = ({ content, dataTestId }) => {
  return (
    <React.Fragment>
      <div data-testid={`${dataTestId}-wrapper`} className="submenu-container">
        <div className="submenu-content">
          <div data-testid={`${dataTestId}-title`} className="submenu-title">
            {content?.title}
          </div>
          <div data-testid={`${dataTestId}-menu-options`} className="submenu">
            {content.subMenuWithoutOptions.map((menu, index) => {
              return (
                <button
                  data-testid={`${dataTestId}-${index}`}
                  key={menu.title}
                  className="submenu-options"
                  type="button"
                  onClick={menu.onClick}
                >
                  {menu.title}
                </button>
              );
            })}
            {content?.subMenuWithOptions.map((menu) => {
              return (
                <DropdownMenu
                  label={menu?.title}
                  menuItems={menu?.subMenuOptions}
                  width={220}
                  variant="default"
                  after={
                    <IconMaterial color="white" icon="keyboard_arrow_down" />
                  }
                  css={{
                    'abyss-dropdown-menu-trigger': {
                      background: 'none',
                      margin: '2px 0px 0px 0px',
                      color: 'white',
                    },
                    'abyss-dropdown-menu-item': {
                      color: '#002677',
                      font: '600',
                    },
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
