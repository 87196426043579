import React from 'react';
import { Banner } from '../Banner';
import { IWantToSection } from '../IWantContent';
import { websiteContent } from '../Utils/website-content';
import { TeamDetails } from './TeamDetails';

export const OfficeBody = () => {
  return (
    <div data-testid="home-page-wrapper" className="body-wrapper">
      <Banner
        page_title={websiteContent.page_title}
        page_description={websiteContent.page_description}
      />
      <div
        data-testid="home-page-subcontainer"
        className="sub-body-below-banner"
      >
        <TeamDetails />
        <div className="vertical-line" />
        <div
          data-testid="home-page-iwanttosection"
          className="i-want-to-wrapper"
        >
          <IWantToSection
            content={websiteContent.iWantToSection_content}
            dataTestId="office-body"
          />
        </div>
      </div>
      <div
        data-testid="home-page-bottom-section-wrapper"
        className="body-content"
      >
        <div data-testid="home-page-bottom-section" className="bottom-section">
          {/* <div className="align-center">Team Priorities</div> */}
          <div data-testid="home-page-points-wrapper" className="points">
            <div
              data-testid="home-page-priority-content"
              className="priority-content"
            >
              <div
                data-testid="priority-image-container"
                className="priority-image-container"
              >
                <img
                  className="priority-title-image"
                  src="./priority-title-image.png"
                  alt="priority-title"
                />
              </div>
              <div
                data-testid="priority-detail-wrapper"
                className="priority-detail"
              >
                <div className="priority-title-wrapper">
                  <div className="priority-title">Technology</div>
                </div>
                <ol className="technology">
                  <li>
                    Artificial Intelligence COE - disease prediction, product
                    selection, financial projections
                  </li>
                  <li>Dashboards- Product, sales, CEO's</li>
                  <li>Health Plan Manager 2.0</li>
                  <li>Monetization of analytics</li>
                </ol>
              </div>
            </div>
            <div
              data-testid="home-page-priority-content"
              className="priority-content"
            >
              <div
                data-testid="priority-image-container"
                className="priority-image-container"
              >
                <img
                  className="priority-title-image"
                  src="priority-title-02.png"
                  alt="priority-title-02"
                />
              </div>
              <div
                data-testid="priority-detail-wrapper"
                className="priority-detail"
              >
                <div className="priority-title">Synergy</div>
                <ul className="synergy">
                  <li>
                    Best practice sharing, leverage broad skill sets, and
                    resource efficiencies
                  </li>
                  <li>Growth office partnerships</li>
                </ul>
              </div>
            </div>
            <div
              data-testid="home-page-priority-content"
              className="priority-content"
            >
              <div
                data-testid="priority-image-container"
                className="priority-image-container"
              >
                <img
                  className="priority-title-image"
                  src="priority-title-03.png"
                  alt="priority-title-03"
                />
              </div>
              <div
                data-testid="priority-detail-wrapper"
                className="priority-detail"
              >
                <div className="priority-title">People</div>
                <ul className="people">
                  <li>Solidify new team</li>
                  <li>Culture of innovation</li>
                  <li>
                    Creation of analytic community (dotted and solid lines)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
