import { websiteContent } from '@src/routes/Utils/website-content';
import React from 'react';

export const TeamDetails = () => {
  return (
    <div className="team-details-wrapper">
      <img
        className="image-container"
        src="./team-detail.png"
        alt="team title"
      />
      <div className="team-detail-content">
        <div className="advocacy-analytics-blade">
          <h5 className="team-title-office">
            {websiteContent.dashboard_content[0].tileTitle}
          </h5>
          <div className="team-description-office">
            {websiteContent.dashboard_content[0].smallDescription}
          </div>
        </div>
        <div className="customer-analysis-solutions-blade">
          <h5 className="team-title-office">
            {websiteContent.dashboard_content[1].tileTitle}
          </h5>
          <div className="team-description-office">
            {websiteContent.dashboard_content[1].smallDescription}
          </div>
        </div>
        <div className="national-accounts-blade">
          <h5 className="team-title-office">
            {websiteContent.dashboard_content[4].tileTitle}
          </h5>
          <div className="team-description-office">
            {websiteContent.dashboard_content[4].smallDescription}
          </div>
        </div>
        <div className="data-resources-blade">
          <h5 className="team-title-office">
            {websiteContent.dashboard_content[2].tileTitle}
          </h5>
          <div className="team-description-office">
            {websiteContent.dashboard_content[2].smallDescription}
          </div>
        </div>
        <div className="uspdra-blade">
          <h5 className="team-title-office">USP Data, Reporting & Analytics</h5>
          <div className="team-description-office">
            {websiteContent.dashboard_content[3].smallDescription}
          </div>
        </div>
      </div>
    </div>
  );
};
