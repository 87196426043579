import React from 'react';
import { Banner } from '../Banner';
import { IWantToSection } from '../IWantContent';
import { iWantToContent } from '../Utils/IWantToContent';
import { submenuContent } from '../Utils/SubMenuDetails';
import { websiteContent } from '../Utils/website-content';
import { AdAnSubmenu } from './AdAnSubMenu/AdAnSubMenu';

export const AdAnBody = () => {
  return (
    <React.Fragment>
      {/* <AdAnSubmenu content={submenuContent.advocacyAnalytics} /> */}
      <Banner
        page_title={websiteContent.dashboard_content[0].tileTitle}
        page_description={websiteContent.dashboard_content[0].tileDescription}
      />
      <div
        style={{
          display: 'flex',
          margin: '5px',
        }}
      >
        <div data-testid="advo-body-wrapper" className="advo-body-wrapper">
          <div
            data-testid="advo-description-container"
            className="advo-description-container"
          >
            <div
              data-testid="advo-description-container-content"
              className="advo-description-container-content left-top"
            >
              <ul>
                <li>Comprehensive Client Reporting (CCR)</li>
                <li>
                  Suite of Business solutions to monitor Operations/ Processes,
                  gauge Program Health, and measure Adverse Determination
                </li>
                <li>Clinical and Behavioral Support Monitoring</li>
                <li>Site action planning for Operations</li>
              </ul>
            </div>

            <div className="advo-description-container-content  left-bottom">
              <ul>
                <li>
                  Use of HealthCare Graph technology for enhanced and integrated
                  analytics
                </li>
                <li>
                  Personalized Smart Offerings for member journey based
                  decisioning and actions
                </li>
              </ul>
            </div>
          </div>
          <img
            className="advo-image-container"
            src="./advo-01.png"
            alt="Advocacy Analytics"
          />

          <div className="advo-description-container">
            <div className="advo-description-container-content right-top">
              <ul>
                <li>Product Value Story</li>
                <li>Advocacy Performance Deep Dives</li>
                <li>Model/ Customer specific analytics</li>
                <li>Pop Id Model value tracking</li>
                <li>Digital engagement analyses</li>
              </ul>
            </div>

            <div className="advo-description-container-content">
              <ul>
                <li>
                  Voice Suite leveraging various speech analytics capabilities
                  like Call Audit, NPS Prediction, Survey Classification etc.
                </li>
                <li>Predictive Modeling</li>
                <li>Member Personas for Personalization</li>
              </ul>
            </div>
          </div>
        </div>
        <IWantToSection
          dataTestId="advocacy-analytics"
          content={iWantToContent.advoAnalytics}
        />
      </div>
    </React.Fragment>
  );
};
