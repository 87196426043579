export const iWantToContent = {
  naa: [
    {
      title: 'Request a Finalist Meeting',
      url: '#',
      href: 'mailto:jennifer.fino@uhc.com?subject=Requesting%20a%20Finalist%20Meeting',
      onClick: () => {
        // window.open('mailto: jennifer.fino@uhc.com');
      },
      // tooltip: 'Coming Soon...',
    },
    {
      title: 'See the Account Manager',
      url: '#',
      onClick: () => {},
      tooltip: 'Coming Soon...',
    },
  ],
  advoAnalytics: [
    {
      title: 'Submit a New Analytics Request',
      href: 'https://forms.office.com/r/VvPgVuUkTB',
    },
    {
      title: 'Check the Status of an Analytics Request',
      href: 'https://uhgazure.sharepoint.com/teams/PremierHubAnalyticsTeam/Lists/Advocacy AdHoc Intake/AllItems.aspx',
    },
    {
      title: 'Contact Advocacy Analytics via MS Teams',
      href: 'https://teams.microsoft.com/l/team/19%3a9db67f1d7d704beeb43655adc07abf0e%40thread.tacv2/conversations?groupId=645fce4f-05b2-476e-9b9a-75bc2d1a4c13&tenantId=db05faca-c82a-4b9d-b9c5-0f64b6755421',
    },
    {
      title: 'Connect to Market',
      href: 'https://ctm.uhc.com/content/ctm/national-accounts/en.html',
    },
    {
      title: 'Find a Report or Dashboard on Analytics Hub',
      href: 'https://intelligence.uhg.com/mashups/6c91f0b8-96fa-4aa4-a770-1c7aa51dfae1',
    },
    {
      title: 'Pull a Client Report',
      href: 'https://acip.uhg.com/report-generator',
    },
    {
      title: 'Generate an Account Performance PowerPoint',
      href: 'http://advocacyanalyticshub.uhc.com/AccountPerformance/AdvocacyHomev1.aspx',
    },
  ],
};
