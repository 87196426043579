import { IWantToButton } from '@src/IWantToButtons';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies

export const IWantToSection = ({ content, dataTestId }) => {
  return (
    <React.Fragment>
      <div
        data-testid={`${dataTestId}-iwanttosection-wrapper`}
        className="button-section"
      >
        <div className="i-want-to-title">I Want To....</div>

        <div
          data-testid={`${dataTestId}-iwanttosection-container`}
          className="button-container"
        >
          <ul
            key={`${dataTestId}-list`}
            data-testid={`${dataTestId}-iwanttosection-list`}
            className="i-want-to-buttons"
          >
            {content?.map((option, index) => {
              return (
                <React.Fragment key={`${option.title}-list-${index}`}>
                  <li
                    data-testid={`${dataTestId}-i-want-to-list-item-${index}`}
                    id={`${option.title}-list-${index}`}
                    onMouseOver={() => {
                      /* istanbul ignore next */
                      if (option.tooltip) {
                        const eleList = document.getElementById(
                          `${option.title}-list-${index}`
                        );
                        const listX =
                          eleList?.getBoundingClientRect().left + 100;
                        const listY = eleList?.getBoundingClientRect().top + 40;
                        const eleDropdown = document.getElementById(
                          `${option.title}-dropdwon-${index}`
                        );
                        eleDropdown.style.transform = `translate3d(${listX}px,${listY}px,0)`;
                        eleDropdown.style.visibility = 'visible';
                      }
                    }}
                    onMouseLeave={() => {
                      const eleDropdown = document.getElementById(
                        `${option.title}-dropdwon-${index}`
                      );
                      eleDropdown.style.visibility = 'hidden';
                    }}
                    onFocus={() => {}}
                  >
                    {/* <button
                      type="button"
                      className="shortcut-button shortcut-button-text"
                      onClick={option.onClick}
                    >
                      <a
                        className="footer-links"
                        href={option.href ? option.href : ''}
                      >
                        {option.title}
                      </a>
                      <div>
                      <span className="tooltip-text">
                        {option.tooltip || ''}
                      </span>
                    </div>
                    </button> */}
                    <IWantToButton title={option.title} href={option.href} />
                  </li>
                  <div
                    style={{
                      top: '0px',
                      left: '0px',
                      position: 'absolute',
                    }}
                  >
                    <div>
                      <div
                        id={`${option.title}-dropdwon-${index}`}
                        style={{
                          top: '0px',
                          left: '0px',
                          position: 'absolute',
                          visibility: 'hidden',
                          background: 'white',
                          fontWeight: '200',
                          color: 'black',
                          width: 'max-content',
                          margin: 'auto',
                          height: '30px',
                          padding: '2px 5px',
                        }}
                      >
                        Coming Soon...
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
