/* eslint-disable import/no-extraneous-dependencies */
import { LogLevel } from '@azure/msal-browser';

const isDevlopment = false;

let redirectUri = isDevlopment
  ? 'http://localhost:4000/'
  : 'https://officeofdataandanalytics.optum.com/';

let hostUrl = '';
if (typeof window !== 'undefined') {
  hostUrl = window.location.href;
}

if (hostUrl.includes('advocacy-analytics')) {
  redirectUri += 'advocacy-analytics';
} else if (hostUrl.includes('customer-analysis-and-solutions')) {
  redirectUri += 'customer-analysis-and-solutions';
} else if (hostUrl.includes('data-resources')) {
  redirectUri += 'data-resources';
} else if (hostUrl.includes('national-accounts')) {
  redirectUri += 'national-accounts';
} else if (hostUrl.includes('clientReporting')) {
  redirectUri += 'uspdra/clientReporting';
} else if (hostUrl.includes('dataEnablement')) {
  redirectUri += 'uspdra/dataEnablement';
} else if (hostUrl.includes('dataExchange')) {
  redirectUri += 'uspdra/dataExchange';
} else if (hostUrl.includes('executiveReporting')) {
  redirectUri += 'uspdra/executiveReporting';
} else if (hostUrl.includes('uspdra')) {
  redirectUri += 'uspdra';
}

/* eslint no-console: ["error", { allow: ["warn", "error", "info", "debug", "log"] }] */

export const msalConfig = {
  auth: {
    clientId: '66cd51d0-0d66-4a64-aad6-882a064c3302',
    authority:
      'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421',
    redirectUri,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const silentRequest = {
  scopes: ['user.read'],
  loginHint: 'example@domain.net',
};
