export const ExecutiveReportingContent = {
  page_title: 'Executive Reporting',
  page_description:
    'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
  navbar_content: {
    topMenuItems: {
      menuItem1: {
        title: 'Dropdown Menu Title',
        menuOptions: [
          {
            label: 'Link 1',
            target: '_blank',
            url: '#',
          },
        ],
      },
      menuItem2: {
        title: 'Dropdown Menu Title',
        menuOptions: [
          {
            label: 'Link 1',
            target: '_blank',
            url: '#',
          },
        ],
      },
      menuItem3: {
        title: 'Dropdown Menu Title',
        menuOptions: [
          {
            label: 'Link 1',
            target: '_blank',
            url: '#',
          },
        ],
      },
    },
  },

  dashboard_content: [
    {
      tileTitle: 'Tile 1 Title',
      tileUrl: '',
      tileImageIsLink: 'Yes/No',
      tileDescription:
        'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
    },
    {
      tileTitle: 'Tile 2 Title',
      tileUrl: '#',
      tileImageIsLink: 'Yes/No',
      tileDescription:
        'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
    },
    {
      tileTitle: 'Tile 3 Title',
      tileUrl: '',
      tileImageIsLink: 'Yes/No',
      tileDescription:
        'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
    },
  ],
  iWantToSection_content: [
    {
      title: 'Link Title 1',
      link: '#',
      tooltip: 'Coming Soon...',
    },
    {
      title: 'Link Title 2',
      link: '#',
      tooltip: 'Coming Soon...',
    },
    {
      title: 'Link Title 3',
      link: '#',
      tooltip: 'Coming Soon...',
    },
    {
      title: 'Link Title 4',
      link: '#',
      tooltip: 'Coming Soon...',
    },
  ],
  aboutus_url: '#',
  contactus_url: '#',
};
