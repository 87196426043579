import React from 'react';
import { Banner } from '../Banner';
import { SubMenu } from '../SubMenu';
import { submenuContent } from '../Utils/SubMenuDetails';
import { websiteContent } from '../Utils/website-content';

export const DataResources = () => {
  return (
    <React.Fragment>
      <SubMenu
        dataTestId="data-resource"
        content={submenuContent.dataResources}
      />
      <div
        data-testid="data-resource-banner-wrapper"
        className="banner-wrapper"
      >
        <Banner
          page_title={websiteContent.dashboard_content[2].tileTitle}
          page_description={websiteContent.dashboard_content[2].tileDescription}
        />
      </div>
      <div data-testid="data-resource-iframe-wrapper" className="frame-wrapper">
        <iframe
          data-testid="data-resource-iframe"
          title="Data resources"
          src="https://uhgazure.sharepoint.com/sites/drs/Connect/SitePages/Home.aspx"
          referrerPolicy="no-referrer"
          width="100%"
          height="1000px"
        />
      </div>
    </React.Fragment>
  );
};
