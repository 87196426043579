export const submenuContent = {
  advocacyAnalytics: {
    title: 'Advocacy Analytics',
    subMenuWithoutOptions: [],
    subMenuWithOptions: [
      {
        title: 'Menu Heading',
        subMenuOptions: [
          {
            title: 'Coming Soon...',
            onClick: () => {
              // window.open('#', '_blank');
            },
          },
        ],
      },
    ],
  },
  customeAnalysisSolutions: {},
  dataResources: {
    title: 'Data Resources',
    subMenuWithoutOptions: [
      {
        title: 'Explore our Team & Services',
        onClick: () => {
          window.open(
            'https://uhgazure.sharepoint.com/sites/drs/Connect/SitePages/Home.aspx',
            '_blank'
          );
        },
      },
    ],
    subMenuWithOptions: [],
  },
  naaa: {
    title: 'National Accounts Advanced Analytics',
    subMenuWithoutOptions: [],
    subMenuWithOptions: [
      {
        title: 'Menu Heading',
        subMenuOptions: [
          {
            title: 'Coming Soon...',
            onClick: () => {
              // window.open('#', '_blank');
            },
          },
          {
            title: 'Coming Soon...',
            onClick: () => {
              // window.open('#', '_blank');
            },
          },
        ],
      },
    ],
  },
  uspdra: {
    title: 'USP Data, Reporting & Analytics',
    subMenuWithoutOptions: [],
    subMenuWithOptions: [
      {
        title: 'Client Reporting',
        subMenuOptions: [
          {
            title: 'Coming Soon...',
            onClick: () => {
              // window.open('#', '_blank');
            },
          },
        ],
      },
      {
        title: 'Data Enablement	',
        subMenuOptions: [
          {
            title: 'Coming Soon...',
            onClick: () => {
              // window.open('#', '_blank');
            },
          },
        ],
      },
      {
        title: 'Data Exchange',
        subMenuOptions: [
          {
            title: 'Coming Soon...',
            onClick: () => {
              // window.open('#', '_blank');
            },
          },
        ],
      },
      {
        title: 'Executive Reporting',
        href: '/executive-reporting',
        subMenuOptions: [
          {
            title: 'Coming Soon...',
            // onClick: () => {
            //   // window.open('#', '_blank');
            // },
          },
        ],
      },
      {
        title: 'USP Operations Reporting & Technology',
        onClick: () => {
          window.open('https://uspopsreporting.uhc.com/', '_blank');
        },
        subMenuOptions: [
          // {
          //   title: 'Visit Our Homepage',
          //   onClick: () => {
          //     window.open('https://uspopsreporting.uhc.com/', '_blank');
          //   },
          // },
        ],
      },
    ],
  },
};
