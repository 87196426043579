/* eslint no-console: ["error", { allow: ["warn", "error", "info", "debug", "log"] }] */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { NavMenu } from '@abyss/web/ui/NavMenu';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Link } from '@abyss/web/ui/Link';
import { useMsal } from '@azure/msal-react';
import { websiteContent } from '../Utils/website-content';
import { DraSubmenu } from '../DraBody/DraSubmenu/DraSubMenu';
import { submenuContent } from '../Utils/SubMenuDetails';

// eslint-disable-next-line react/prop-types
export const Navbar = ({ title }) => {
  const { instance } = useMsal();
  const [showLinkToUspOpsTechTeamsPage, setShowLinkToUspOpsTechTeamsPage] =
    useState(false);

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    // console.log(activeAccount);
  }

  const [hours] = useState(new Date().getHours());
  const [username] = useState('Test');

  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon ');
    } else if (hours >= 16) {
      setGreeting('Good evening ');
    }
  }, [greeting, hours]);

  useEffect(() => {
    setInterval(() => {
      if (typeof window !== 'undefined') {
        const hostUrl = window.location.href;
        if (hostUrl.includes('uspdra')) {
          setShowLinkToUspOpsTechTeamsPage(true);
        } else {
          setShowLinkToUspOpsTechTeamsPage(false);
        }
      }
    }, 100);
  });

  const topMenuItems = [
    {
      title: websiteContent.navbar_content.topMenuItems.menuItem1.title,
      href: '/',
    },
    {
      title: websiteContent.navbar_content.topMenuItems.menuItem2.title,
      href: '/advocacy-analytics',
    },
    {
      title: websiteContent.navbar_content.topMenuItems.menuItem3.title,
      href: '/customer-analysis-and-solutions',
    },
    {
      title: websiteContent.navbar_content.topMenuItems.menuItem4.title,
      href: '/data-resources',
    },
    {
      title: websiteContent.navbar_content.topMenuItems.menuItem5.title,
      href: '/national-accounts',
    },
    {
      title: websiteContent.navbar_content.topMenuItems.menuItem6.title,
      href: '/uspdra',
      content: (
        <NavMenu.Columns>
          <NavMenu.Column>
            {websiteContent.navbar_content.topMenuItems.menuItem6.menuOptions.map(
              (menuItem, index) => {
                return (
                  <NavMenu.Item
                    key={index}
                    title={menuItem.title}
                    href={menuItem.url}
                  />
                );
              }
            )}
          </NavMenu.Column>
        </NavMenu.Columns>
      ),
    },
  ];

  return (
    <React.Fragment>
      <PageHeader
        data-testid="navbar"
        logo={
          <React.Fragment>
            <div data-testid="greeting-wrapper">
              <Brandmark
                brand="uhc"
                size="120px"
                affiliate="uhc"
                variant="lockup"
                color="blue"
                data-testid="uhc-logo"
              />

              {/* <h5 className="greeting">Welcome to {title}</h5> */}
              <h5 className="greeting-secondary">
                {greeting}
                {activeAccount ? `${activeAccount.name} ` : username}
              </h5>
            </div>
          </React.Fragment>
        }
        fullWidth
        model="pageheader-model"
        topMenuItems={topMenuItems}
        // bottomMenuItems={bottomMenuItems}
        css={{
          'abyss-page-header-root': { zIndex: 209 },
          'abyss-nav-menu-viewport-container': { width: '30%' },
        }}
      >
        {showLinkToUspOpsTechTeamsPage && (
          <div className="secondary-menu">
            <Link
              className="remove-link-underline"
              size="xs"
              color="white"
              colorHover="white"
              href="https://uspopsreporting.uhc.com/"
            >
              {/* <div
            className="subMenu-title"
            onClick={submenuContent.uspdra.subMenuWithOptions[4].onClick}
            onKeyDown={() => {}}
            tabIndex={0}
            role="button"
          > */}
              {submenuContent.uspdra?.subMenuWithOptions[4].title}
              {/* </div> */}
            </Link>
          </div>
        )}
        {/* </div>
        </div> */}
      </PageHeader>
    </React.Fragment>
  );
};
