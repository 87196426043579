import React from 'react';

export const Banner = ({ page_title, page_description }) => {
  return (
    <React.Fragment>
      <div className="banner">
        <div className="banner-content">
          <div className="banner-title">Welcome to {page_title}</div>
          <div className="banner-subcontent">{page_description}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
