import React from 'react';
import { Banner } from '../Banner';
import { IWantToSection } from '../IWantContent';
import { iWantToContent } from '../Utils/IWantToContent';
import { websiteContent } from '../Utils/website-content';

export const NaaaBody = () => {
  return (
    <React.Fragment>
      {/* <NaaaSubMenu content={submenuContent.naaa} /> */}
      <Banner
        page_title={websiteContent.dashboard_content[4].tileTitle}
        page_description={websiteContent.dashboard_content[4].tileDescription}
      />
      <div data-testid="naaa-body-wrapper" className="naaa-body-wrapper">
        <div
          data-testid="headline-content-wrapper"
          className="headline-content-wrapper"
        >
          <div data-testid="headline-content" className="headline-content">
            <div data-testid="naaa-content" className="naaa-content">
              <div className="ribbon">WHAT WE DO</div>
              <div
                data-testid="naaa-list-container"
                className="naaa-list-container"
              >
                <ol>
                  <li>
                    Champion and lead innovative and forward-looking analytics
                    that differentiate us in the marketplace and inform our and
                    our clients’ healthcare strategies
                  </li>
                  <li>
                    Curate differentiating and leading analytics focused on
                    today’s top and emerging health care priorities for
                    HR/benefit leaders
                  </li>
                  <li>
                    Reinforce our value story with analytics that fortify our
                    commitments, highlight pinnacle performance, and create the
                    success stories for our HR/benefit leaders
                  </li>
                  <li>
                    Support growth and retention strategies by actively
                    participating in finalist meetings, capability meetings,
                    external and internal conferences, speaking engagements,
                    etc.
                  </li>
                  <li>
                    Inform, implement and measure customer communication
                    strategies in partnership with Engagement Solutions
                  </li>
                </ol>
              </div>
            </div>
            <div
              data-testid="naaa-image-container"
              className="naaa-image-container"
            >
              <img
                className="naaa-image"
                src="./naa-01.jpg"
                alt="National Accounts Advanced Analytics logo"
              />
            </div>
          </div>
          <IWantToSection dataTestId="naaa-body" content={iWantToContent.naa} />
        </div>
        <div data-testid="client-content" className="client-content">
          <div className="ribbon">OUR CLIENTS</div>
          <div
            data-testid="naaa-client-image-container"
            className="naaa-client-image-container"
          >
            <img
              src="./naa-clients.png"
              alt="National Accounts Advanced Analytics Clients"
              className="naaa-client-image"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
