import { Banner } from '@src/routes/Banner';
import { IWantToSection } from '@src/routes/IWantContent';
import { MainContent } from '@src/routes/MainContent';
import { PageDescription } from '@src/routes/PageDescription';
import { ExecutiveReportingContent } from '@src/routes/Utils/executiveReportingContent';
import React from 'react';

export const ClientReporting = () => {
  return (
    <React.Fragment>
      <div className="banner-wrapper">
        <Banner
          page_title="Client Reporting"
          page_description={ExecutiveReportingContent.page_description}
        />
      </div>

      <div className="flex-div">
        <MainContent
          dashboard_content={ExecutiveReportingContent.dashboard_content}
        />
        <div className="i-want-to-content-wrapper">
          <IWantToSection
            dataTestId="ClientReporting"
            content={ExecutiveReportingContent.iWantToSection_content}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
