import React from 'react';
import { PageFooter } from '@abyss/web/ui/PageFooter';

export const Footer = ({ aboutUs, contactUs }) => {
  return (
    <PageFooter
      data-testid="office-page"
      css={{
        'abyss-page-footer-root': {
          background: '#002677',
          color: 'white',
          margin: '0px 0px 0px 0px',
          minHeight: '25px',
          padding: '2px',
        },
        'abyss-page-footer-sub-wrapper': {
          color: '#fff',
        },
        'abyss-page-footer-container': {
          padding: '0px px 0px 0px',
          width: '90%',
          margin: 'auto',
        },
      }}
      subFooterTitle="United HealthCare Services, Inc"
    >
      {/* <PageFooter.Section
        css={{
          'abyss-page-footer-section': {
            height: '10px',
          },
        }}
      /> */}
      {/* <PageFooter.Section
        css={{
          'abyss-page-footer-section-title': {
            color: 'white',
          },
        }}
      >
        <PageFooter.Link
          css={{
            'abyss-page-footer-link': {
              color: '#fff',
            },
          }}
        >
          <a className="footer-links" href="#" target="_blank" rel="noreferrer">
            About Us
          </a>
        </PageFooter.Link>
      </PageFooter.Section> */}
      {contactUs && (
        <PageFooter.Section
          data-testid="office-page"
          css={{
            'abyss-page-footer-section-title': {
              display: 'none',
            },
            'abyss-page-footer-section': {
              // width: '80%',
              // margin: 'auto',
            },
          }}
        >
          {/* <PageFooter.Link
            css={{
              'abyss-page-footer-link': {
                color: '#fff',
              },
            }}
          > */}
          <a className="footer-links" href={contactUs}>
            Contact Us
          </a>
          {/* </PageFooter.Link> */}
        </PageFooter.Section>
      )}
    </PageFooter>
  );
};
