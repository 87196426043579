import React from 'react';
import { websiteContent } from '../Utils/website-content';
import { Banner } from '../Banner';
import { IWantToSection } from '../IWantContent';
// import { DraSubmenu } from './DraSubmenu/DraSubMenu';
// import { submenuContent } from '../Utils/SubMenuDetails';

export const DraBody = () => {
  return (
    <React.Fragment>
      {/* <DraSubmenu content={submenuContent.uspdra} /> */}

      {/* Banner of the Page  starts */}
      <div data-testid="uspdra-banner-wrapper" className="banner-wrapper">
        <Banner
          page_title={websiteContent.dashboard_content[3].tileTitle}
          page_description={websiteContent.dashboard_content[3].tileDescription}
        />
        {/* <DraSubmenu content={submenuContent.uspdra} /> */}
      </div>
      {/* Banner of page ends */}

      {/* Main body of USPDRA starts */}

      <div data-testid="uspdra-body-wrapper" className="uspdra-body-wrapper">
        <div data-testid="uspdra-top-section" className="uspdra-top-section">
          <div
            data-testid="uspdra-content-wrapper"
            className="uspdra-content-wrapper"
          >
            {/* <div className="uspdra-image-container">
              <img
                className="heirarchy-image"
                src="/uspdra-01.png"
                alt="USPDRA heirarchy "
              />
            </div> */}

            <div
              data-testid="uspdra-details-wrapper"
              className="uspdra-details-wrapper"
            >
              <div>
                <section className="uspdra-detail-heading">
                  Who we are and what we do
                </section>
                <ul>
                  <li>
                    We are a team of UHC data SMEs, operational experts, and
                    technology-strategists.
                  </li>
                  <li>
                    We bridge across the USP Program teams, IT Data teams, and
                    Business Reporting teams
                  </li>
                  <li>
                    We ensure that USP data is flowing across E&I timely,
                    accurately, and completely
                  </li>
                </ul>
              </div>

              <img
                className="uspdra-image-01"
                src="./Heathcare_Data.png"
                alt="USPDRA Healthcare"
              />
            </div>
          </div>
          <IWantToSection
            dataTestId="uspdra"
            content={websiteContent.iWantToSection_content}
          />
        </div>

        <div
          data-testid="uspdra-bottom-section"
          className="uspdra-bottom-section"
        >
          <div
            data-testid="uspdra-bottom-section-top"
            className="bottom-section-top"
          >
            <img
              className="dashboard-screenshots"
              src="./uspdra-combine-screenshot.png"
              alt="USPDRA Dashboards Screenshot"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
